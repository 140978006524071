// import { I18n } from "i18n-js"
// window.I18n = I18n
// console.log('I18n file')
// console.log(I18n)
// console.log('test i18n',I18n.t('fee.fee_master'))

import { I18n as I18nClass } from 'i18n-js';
import translations from "../locales.json";
const i18nInstance= new I18nClass(translations, {defaultLocale: "en"});
i18nInstance.locale = "en";
window.I18n = i18nInstance